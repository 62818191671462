// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT
//

import React, { SyntheticEvent } from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import './styles.scss';

import {
    Col, Input, Pagination, Row, Spin,
} from 'antd';

import Text from 'antd/lib/typography/Text';

import UserItemComponent from './user-item-component';

interface TasksPageProps {
    usersFetching: boolean;
    users: any[];
    count: number | null
    next: null | string
    getUsersList: (page: number) => void
}

interface ComponentState {
    modalType?: string;
    search: string;
    deletedIds: number[];
}

class UsersPageComponent extends React.PureComponent<TasksPageProps & RouteComponentProps, ComponentState> {
    constructor(props: TasksPageProps & RouteComponentProps) {
        super(props);
        this.state = {
            modalType: undefined,
            search: '',
            deletedIds: [],
        };
    }

    public componentDidMount(): void {
        const { location, getUsersList } = this.props;
        this.updateUserList(new URLSearchParams(location.search));

        if (location.search[6]) {
            getUsersList(+location.search[6])
        } else {
            getUsersList(1)
        }

    }

    private onSwitchPage(value: number): void {
        const { location, history, getUsersList } = this.props;

        const params = new URLSearchParams(location.search);
        params.set('page', String(value));
        history.push({
            search: params.toString(),
        });
        getUsersList(value)
    }

    private onSearch = (searchString: string): void => {
        const { location, history } = this.props;

        const params = new URLSearchParams(location.search);
        if (searchString) {
            params.set('search', searchString);
        } else {
            params.delete('search');
        }
        history.push({
            search: params.toString(),
        });
    };

    private deleteUser(id: number): void {
        const { deletedIds } = this.state;
        this.setState((prevState) => ({
            ...prevState,
            deletedIds: [...deletedIds, id],
        }));
    }

    public updateUserList(params: URLSearchParams): void {
        const search = params.get('search');
        this.setState((prevState) => ({
            ...prevState,
            search: search === null ? '' : search,
        }));
    }

    public render(): JSX.Element {
        const { usersFetching, users, location, count, next } = this.props;

        const { deletedIds, search } = this.state;

        if (usersFetching) {
            return <Spin size='large' className='cvat-spinner' />;
        }

        const params = new URLSearchParams(location.search);
        const gotPage = params.get('page');
        const currentPage = gotPage === null ? 1 : parseInt(gotPage, 10);
        const project = params.get('project');
        const searchParam = params.get('search');
        const adminFilter = params.get('admin');

        const usersFiltered = users.filter((user) => {
            if (deletedIds.includes(user.id)) {
                return false;
            }
            if (adminFilter && !user.groups.includes('admin')) {
                return false;
            }
            const projectID = project === null ? NaN : parseInt(project, 10);
            if (projectID) {
                if (!user.projects.includes(projectID)) {
                    return false;
                }
            }
            if (searchParam) {
                const usersVariousNames = `${user.firstName || ''} \
                    ${user.lastName || ''} \
                    ${user.email || ''} \
                    ${user.username || ''}`.toLowerCase();
                if (!usersVariousNames.includes(searchParam.toLowerCase())) {
                    return false;
                }
            }
            return true;
        });

        interface HasUsername {
            username: string;
        }
        const usersComparator = (usrA: HasUsername, usrB: HasUsername) => usrA.username.localeCompare(usrB.username);

        const usersSorted = usersFiltered.sort(usersComparator);
        // usersSorted.forEach((usr, index) => {
        //     // eslint-disable-next-line no-param-reassign
        //     usr.listID = index + 1;
        // });

        const usersWithInvites = [...usersSorted];
        const usersOnCurrentPage = usersWithInvites;
        // const usersOnCurrentPage = usersWithInvites.slice((currentPage - 1) * 10, currentPage * 10);

        return (
            <div className='cvat-users-page'>
                <Row justify='center' align='middle'>
                    <Col md={22} lg={18} xl={16} xxl={14} />
                </Row>
                <Row justify='center' align='middle'>
                    <Col md={11} lg={9} xl={8} xxl={7}>
                        <Text className='cvat-title'>Users</Text>
                        <Input.Search
                            defaultValue=''
                            value={search}
                            onSearch={this.onSearch}
                            size='large'
                            onChange={(event: SyntheticEvent) => {
                                const input = event.target as HTMLInputElement;
                                this.setState((prevProps) => ({
                                    ...prevProps,
                                    search: input.value,
                                }));
                            }}
                            placeholder='Search'
                        />
                    </Col>
                    <Col
                        className='cvat-user-list'
                        md={{ span: 11 }}
                        lg={{ span: 9 }}
                        xl={{ span: 8 }}
                        xxl={{ span: 7 }}
                    />
                </Row>
                <Row
                    justify='center'
                    style={{
                        height: 'calc(100% - 200px)',
                        overflow: 'auto',
                    }}
                >
                    <Col className='cvat-user-list' md={22} lg={18} xl={16} xxl={14}>
                        <UserItemComponent
                            usersOnCurrentPage={usersOnCurrentPage}
                            deleteUserFromList={(id: number) => this.deleteUser.call(this, id)}
                            currentPage={currentPage}
                            count={count}
                            onChangePagination={(value) => this.onSwitchPage(value)}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

export default withRouter(UsersPageComponent);
