import React from 'react'
import { PieChart, Pie, Tooltip } from 'recharts';
import { Empty } from 'antd';

interface IProps {
    data: {
        [key: string]: number
    }
}

interface IPie {
    [key: string]: number
}

const DashboardPieChart = ({ data }: IProps): JSX.Element => {

    function dataConstructorForPieChart(dataObject: any): any {

        if (dataObject) {
            let arrData: any = [];

            let keys = Object
                .keys(dataObject)
                .filter((el: any) => el !== 'count')

            keys.forEach((el: any): IPie => arrData.push({ name: `${el.slice(4, el.length)}`, value: dataObject[el] }))

            return arrData
        }

    }

    return (
        <div className="dashboard-card">
            <h2>Total count: {data.count}</h2>
            {data.count === 0 && <Empty />}
            {data.count > 0 && <PieChart width={300} height={200}>
                <Pie
                    dataKey="value"
                    isAnimationActive={false}
                    data={dataConstructorForPieChart(data)}
                    cx="50%"
                    cy="50%"
                    outerRadius={80}
                    fill="#8884d8"
                    label
                    paddingAngle={5}
                />
                <Tooltip />
            </PieChart>}
        </div>
    )
}

export default DashboardPieChart
