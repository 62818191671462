import React, { useState } from 'react'
import { Select, Modal, Button, notification } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { CombinedState } from 'reducers/interfaces';
import { getProjectsForAskForNewTask } from '../../actions/projects-actions';

const { Option } = Select;

interface Props {
    visible: boolean
    onCancel: () => any
    onSubmit: (id: number) => any
}

const ChooseProjectModal: React.FC<Props> = ({ visible, onCancel, onSubmit }): JSX.Element => {

    const [taskValue, setTaskValue] = useState<boolean | null>(null)
    const { get_new_task } = useSelector((state: CombinedState) => state.tasks)
    const { projectsForNewtask: { loading, list } } = useSelector((state: CombinedState) => state.projects)
    const auth = useSelector((state: CombinedState) => state.auth);
    const dispatch = useDispatch()

    const USER_ID = auth.user.id;

    React.useEffect(() => {
        if (visible === true) {
            dispatch(getProjectsForAskForNewTask(USER_ID))
        }
    }, [visible])

    React.useEffect(() => {
        if (get_new_task.job_id && get_new_task.task_id && get_new_task.error === null) {
            return notification.success({
                message: 'Success',
                duration: 8,
                description: <a href={`/tasks/${get_new_task.task_id}/jobs/${get_new_task.job_id}`}>Go to JOB</a>
            })
        }
        if (get_new_task.error && get_new_task.job_id === null) {
            return notification.info({
                message: 'Info',
                duration: 5,
                description: 'No free tasks'
            })
        }


    }, [get_new_task])

    React.useEffect(() => {
        return () => {
            dispatch({ type: 'ASK_FOR_NEW_TASK_RESET' })
        }
    }, [])

    return (
        <Modal
            title="Choose Project"
            visible={visible}
            onCancel={onCancel}
            footer={[
                <Button key="submit" type="primary" disabled={!taskValue ? true : false} onClick={() => onSubmit(taskValue)}>
                    Submit
                </Button>
            ]}
        >
            <Select
                style={{ width: 200 }}
                showSearch
                placeholder="Choose Project"
                value={taskValue}
                onChange={(e: number) => setTaskValue(e)}
                loading={loading}
            >
                {list.length > 0 && list.map((el: any) => <Option key={el.id} value={el.id}>{el.name}</Option>)}
            </Select>
        </Modal>
    )
}

export default React.memo(ChooseProjectModal)
