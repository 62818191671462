// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT
//
import React from 'react';
import { connect } from 'react-redux';
import { Button, Popconfirm, Table, Tag } from 'antd';
import moment from 'moment';
import { CombinedState } from '../../reducers/interfaces';
import { deleteUser, editUsersAdminStatus, sendInvite } from '../../actions/users-actions';

interface UserItemProps {
    usersOnCurrentPage: any;
    deleteUserFromList: any;
    currentPage: number;
    count: number | any;
    onChangePagination: (value: number) => void
}

interface StateToProps {
    currentUser: any;
}

interface DispatchToProps {
    deleteUserItem(userID: number): void;
    editUsersAdminStatus(userID: number, shouldBeAdmin: boolean): void;
    sendInvite(emails: string[]): void;
}

function mapStateToProps(state: CombinedState): StateToProps {
    return {
        currentUser: state.auth.user,
    };
}

function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        deleteUserItem: (userID: number): void => {
            dispatch(deleteUser(userID));
        },
        editUsersAdminStatus: (userID: number, shouldBeAdmin: boolean): void => {
            dispatch(editUsersAdminStatus(userID, shouldBeAdmin));
        },
        sendInvite: (emails: string[]): void => {
            dispatch(sendInvite(emails));
        },
    };
}

type Props = UserItemProps & DispatchToProps & StateToProps;

interface TypesRolesColor {
    [key: string]: string
}

let rolesColor: TypesRolesColor = {
    admin: 'red',
    client: 'green',
    annotator: 'yellow',
    observer: '#7817b1',
    user: '#1cc7b9'
}

const UserItemComponent: React.FC<Props> = (props): JSX.Element => {

    const { currentUser, deleteUserFromList, usersOnCurrentPage, deleteUserItem, currentPage, count, onChangePagination } = props;

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            sorter: (a: any, b: any) => a.id - b.id,
        },
        {
            title: 'User Name',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: 'Groups',
            width: 250,
            dataIndex: 'groups',
            key: 'groups',
            render: (groups: any) => groups.map((el: string) => <Tag color={rolesColor[el]} key={el}>{el.toUpperCase()}</Tag>)
        },
        {
            title: 'Last Login',
            dataIndex: 'last_login',
            key: 'last_login',
            render: (last_login: string) =>  moment(last_login).format('MMMM Do YYYY, h:mma')
        },
        {
            title: 'Date joined',
            dataIndex: 'date_joined',
            key: 'date_joined',
            render: (date_joined: any) => moment(date_joined).format('YYYY-MM-DD')
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text: any, record: any) => currentUser.id !== record.id && (
                <Popconfirm
                    title="Are you sure to delete this user?"
                    onConfirm={() => {
                        deleteUserItem(record.id);
                        deleteUserFromList(record.id);
                    }}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="text"
                        danger
                    > Delete</Button>
                </Popconfirm>
            )

        }
    ]

    return <Table
        dataSource={usersOnCurrentPage}
        columns={columns}
        pagination={{
            total: count,
            current: currentPage,
            pageSize: 10,
            onChange: onChangePagination,
            showSizeChanger: false
        }}
    />;
}

export default connect(mapStateToProps, mapDispatchToProps)(UserItemComponent);
