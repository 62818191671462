// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';

import {
    Col,
    Row,
    Button,
} from 'antd';

import Text from 'antd/lib/typography/Text';
import { CombinedState, JobAssignee, ROLES, TasksQuery } from "../../reducers/interfaces";
import { connect } from "react-redux";
// import {isPermissible, PermissionTypes, UserRole} from "../../settings";
import { getNewTask } from "../../actions/tasks-actions";
import ChooseProjectModal from "./choose-project-modal";
import FiltersWrapper from 'components/filters/filters-wrapper';

interface VisibleTopBarProps {
    // searchValue: string;
    gettingQuery: TasksQuery;
    updateURL: any;
}

interface ComponentState {
    modalType: string | null;
}

interface StateToProps {
    // userGroups: UserRole[];
    userGroups: any[];
    job_assignees_list: JobAssignee
}

type Props = VisibleTopBarProps & StateToProps & DispatchToProps & RouteComponentProps;

function mapStateToProps(state: CombinedState): StateToProps {
    const {
        auth: {
            user: {
                groups: userGroups,
            }
        },
    } = state;

    const { job_assignees_list } = state.tasks

    return {
        userGroups,
        job_assignees_list
    }
}

interface DispatchToProps {
    askForNewTask(projectID: number): void;
}

function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        askForNewTask(projectID: number): void {
            dispatch(getNewTask(projectID));
        },
    }
}

class TopBarComponent extends React.Component<any, ComponentState> {
    // class TopBarComponent extends React.Component<Props, ComponentState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            modalType: null,
        };
    }

    render() {
        const {
            askForNewTask,
            userGroups,
            updateURL,
            gettingQuery,
            job_assignees_list
        } = this.props;

        const closeModal = () => {
            this.setState({ modalType: null });
        };

        const finishModalChooseProject = (id: number) => {
            askForNewTask(id)
            this.setState({ modalType: null });
        }

        function onReset() {
            let query = { ...gettingQuery }
            query.page = 1;
            updateURL({ page: 1 })
        }

        function onSort(type: string): void {
            let query = { ...gettingQuery }
            query.sort = type;

            updateURL(query)
        }

        function onChangeFilter(value: string, none_value: string, filter_name: string): void {
            let query = { ...gettingQuery }
            query.sort = null;
            query.page = 1;
            query[filter_name] = value === none_value ? null : value
            updateURL(query)
        }

        return (
            <>
                <Row justify='center' align='top'>
                    <Col md={11} lg={9} xl={8} xxl={7}>
                        <Text className='cvat-title'>Tasks</Text>
                        <FiltersWrapper
                            onReset={onReset}
                            onSort={onSort}
                            onChangeFilter={onChangeFilter}
                            gettingQuery={gettingQuery}
                            job_assignees_list={job_assignees_list || []}
                        />
                    </Col>
                    <Col
                        md={{ span: 11 }}
                        lg={{ span: 9 }}
                        xl={{ span: 8 }}
                        xxl={{ span: 7 }}
                    >
                        {
                            !userGroups.includes(ROLES.CLIENT) && <Button
                                size='large'
                                style={{ marginRight: '10px' }}
                                onClick={() => {
                                    this.setState({
                                        ...this.state,
                                        modalType: 'choose-project'
                                    })
                                }}
                            >
                                Ask for a new task
                            </Button>
                        }
                        {

                            <Button
                                size='large'
                                id='cvat-create-task-button'
                                type='primary'
                                onClick={(): void => this.props.history.push('/tasks/create')}
                            >
                                Create new task
                            </Button>
                        }
                    </Col>
                </Row>

                <ChooseProjectModal
                    visible={this.state.modalType === 'choose-project'}
                    onCancel={closeModal}
                    onSubmit={finishModalChooseProject}
                />
            </>
        );
    }

}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
)(TopBarComponent));