import React from 'react';
import { Form, Select } from 'antd';
import { JobAssignee } from 'reducers/interfaces';

const { Option } = Select;

interface IProps {
    onChangeFilter: (value: string, none_value: string, filter_name: string) => void
    value: string | null;
    job_assignees_list?: JobAssignee;
}

const FilterAssignee = ({ onChangeFilter, value, job_assignees_list = [] }: IProps) => {
    return (
        <Form.Item label='assignee:'>
            <Select
                onChange={(value: string) => onChangeFilter(value, 'any', 'assignee')}
                defaultValue='any'
                placeholder='please select'
                value={value}
            >
                <Option value='null'>unassigned</Option>
                <Option value='any'>- any -</Option>
                {job_assignees_list.map((element: any, index: number) => <Option key={`${element.user_id}`} value={`${element.user_id}`}>{element.username}</Option>)}
            </Select>
        </Form.Item>
    )
}

export default FilterAssignee