// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import './styles.scss';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import Spin from 'antd/lib/spin';
import { Row, Col } from 'antd/lib/grid';
import Title from 'antd/lib/typography/Title';
import Button from 'antd/lib/button';
import Result from 'antd/lib/result';
import {
    PlusOutlined
} from '@ant-design/icons';
import Pagination from 'antd/lib/pagination';

import { cancelInferenceAsync } from 'actions/models-actions';
import TaskItem from 'components/tasks-page/task-item';
import { CombinedState, Task } from 'reducers/interfaces';
import DetailsComponent from './details';
import ProjectTopBar from './top-bar';
import { getProjectByIDAsync } from '../../actions/projects-actions';
import FiltersWrapper from 'components/filters/filters-wrapper';
interface ParamType {
    id: string;
}

const PAGE_SIZE = 10;

export default function ProjectPageComponent(props: any): JSX.Element {
    const id = +useParams<ParamType>().id;
    const dispatch = useDispatch();
    const history = useHistory();
    const projects = useSelector((state: CombinedState) => state.projects.current);
    const projectsFetching = useSelector((state: CombinedState) => state.projects.fetching);
    const deletes = useSelector((state: CombinedState) => state.projects.activities.deletes);
    const taskDeletes = useSelector((state: CombinedState) => state.tasks.activities.deletes);
    const tasksActiveInferences = useSelector((state: CombinedState) => state.models.inferences);
    const tasks = useSelector((state: CombinedState) => state.tasks.current);
    const { count } = useSelector((state: CombinedState) => state.tasks)
    const { projectFiltersQuery, job_assignees_list } = useSelector((state: CombinedState) => state.projects)

    const filteredProjects = projects.filter((project) => project.id === id);
    const project = filteredProjects[0];
    const deleteActivity = project && id in deletes ? deletes[id] : null;
    const [isFilterModalVisible, setIsFilterModalVisible] = React.useState(false);

    useEffect(() => {
        const filteredQuery = { ...projectFiltersQuery };

        for (const key in filteredQuery) {
            if (filteredQuery[key] === null) {
                delete filteredQuery[key];
            }
        }
        const params = new URLSearchParams(filteredQuery);
        history.replace({
            search: params.toString()
        })

    }, [projectFiltersQuery])

    useEffect(() => {
        const filteredQuery: any = { page: 1, page_size: PAGE_SIZE };
        const params = new URLSearchParams(props.location.search)

        for (const key in projectFiltersQuery) {
            if (params.get(key)) {
                filteredQuery[key] = params.get(key)
            }
        }

        dispatch(getProjectByIDAsync(id, filteredQuery));
    }, [id, dispatch]);

    if (deleteActivity) {
        history.push('/projects');
    }

    if (projectsFetching) {
        return <Spin size='large' className='cvat-spinner' />;
    }

    if (!project) {
        return (
            <Result
                className='cvat-not-found'
                status='404'
                title='Sorry, but this project was not found'
                subTitle='Please, be sure information you tried to get exist and you have access'
            />
        );
    }

    function onReset() {
        dispatch(
            getProjectByIDAsync(id, { page: 1, page_size: PAGE_SIZE }),
        );
    }

    function changePage(page: number, pageSize: number): void {

        let actualQuery = { ...projectFiltersQuery, page: page, page_size: pageSize };

        dispatch(
            getProjectByIDAsync(id, actualQuery),
        );
    }

    function onSort(type: string): void {
        let actualQuery = { ...projectFiltersQuery, sort: projectFiltersQuery.sort === type ? null : type };

        dispatch(
            getProjectByIDAsync(id, actualQuery),
        );
    }

    function onChangeFilter(value: string, none_value: string, filter_name: string): void {

        let { page, page_size } = projectFiltersQuery;
        // let actualQuery = { ...projectFiltersQuery, [filter_name]: value === none_value ? null : value }; for many filters
        let actualQuery = { page, page_size, [filter_name]: value === none_value ? null : value }; //only for one

        dispatch(
            getProjectByIDAsync(id, actualQuery),
        );
    }

    return (
        <Row justify='center' align='top' className='cvat-project-page'>
            <Col md={22} lg={18} xl={16} xxl={14}>
                <ProjectTopBar projectInstance={project} />
                <DetailsComponent project={project} />
                <Row justify='space-between' align='middle' className='cvat-project-page-tasks-bar'>
                    <Col>
                        <Title level={4} style={{ display: 'inline-block', marginRight: '20px' }}>
                            Tasks
                        </Title>
                        {
                            tasks && <FiltersWrapper
                                onReset={onReset}
                                onSort={onSort}
                                onChangeFilter={onChangeFilter}
                                gettingQuery={projectFiltersQuery}
                                job_assignees_list={job_assignees_list || []}
                            />
                        }
                    </Col>
                    <Col>
                        <Button
                            size='large'
                            type='primary'
                            icon={<PlusOutlined />}
                            id='cvat-create-task-button'
                            onClick={() => history.push(`/tasks/create?projectId=${id}`)}
                        >
                            Create new task
                        </Button>
                    </Col>
                </Row>
                {
                    tasks.map((task: Task) => (
                        <TaskItem
                            key={task.instance.id}
                            deleted={task.instance.id in taskDeletes ? taskDeletes[task.instance.id] : false}
                            hidden={false}
                            activeInference={tasksActiveInferences[task.instance.id] || null}
                            cancelAutoAnnotation={() => {
                                dispatch(cancelInferenceAsync(task.instance.id));
                            }}
                            previewImage={task.preview}
                            taskInstance={task.instance}
                        />
                    ))
                }
                <Row Row justify='center' align='middle'>
                    <Col md={22} lg={18} xl={16} xxl={14}>
                        <Pagination
                            className='cvat-tasks-pagination'
                            onChange={changePage}
                            total={count}
                            pageSize={+projectFiltersQuery.page_size}
                            current={+projectFiltersQuery.page}
                            pageSizeOptions={[10, 15, 20, 25]}
                            showSizeChanger={true}
                        />
                    </Col>
                </Row>
            </Col >
        </Row >
    );
}
