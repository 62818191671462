// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { useDispatch } from 'react-redux';
import Modal from 'antd/lib/modal';
import Menu from 'antd/lib/menu';
import Text from 'antd/lib/typography/Text';
import { DownloadOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

import { clearInfoErrorAsync, deleteProjectAsync } from 'actions/projects-actions';
import { getInfoCountAbotProjectsTasksAsync } from '../../actions/projects-actions';
import { useSelector } from 'react-redux';
import { CombinedState, DimensionType, TOTAL_COUNTS_OF_PROJECT } from 'reducers/interfaces';
import { useEffect } from 'react';
import { notification } from 'antd';
import { Actions } from 'components/actions-menu/actions-menu';
import { dumpAllTasksAsync } from '../../actions/projects-actions';
import ExportSubmenu from '../actions-menu/export-submenu';
import EditProjectContent from './edit-projects-content';
import { updateProjectAsync } from 'actions/projects-actions';

interface Props {
    projectInstance: any;
}

export default function ProjectActionsMenuComponent(props: Props): JSX.Element {
    const { projectInstance } = props;

    const dispatch = useDispatch();
    const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);
    const { dumpers } = useSelector((state: CombinedState) => state.formats.annotationFormats)
    const { current, infoError, infoCounts, infoFetching, dumpFetching } = useSelector((state: CombinedState) => state.projects)

    useEffect(() => {
        if (infoError) {
            notification.error({
                message: 'Error',
                duration: 4,
                description: infoError
            });
        }
    }, [infoError])

    useEffect(() => {
        return () => dispatch(clearInfoErrorAsync())
    }, [])
    const [modal, setModal] = React.useState<string | null>(null)

    const onDeleteProject = (): void => {
        Modal.confirm({
            title: `The project ${projectInstance.id} will be deleted`,
            content: 'All related data (images, annotations) will be lost. Continue?',
            className: 'cvat-modal-confirm-remove-project',
            onOk: () => {
                dispatch(deleteProjectAsync(projectInstance));
            },
            okButtonProps: {
                type: 'primary',
                danger: true,
            },
            okText: 'Delete',
        });
    };

    const onClickMenuWrapper = (info: any): void => {
        let { key, keyPath } = info;
        let [, keyName] = keyPath;
        let checkKey = keyName || key

        switch (checkKey) {
            case Actions.DUMP_TASK_ANNO:
                dumpTasks(key, 'annotations')
                break;
            case Actions.EXPORT_TASK_DATASET:
                dumpTasks(key, 'dataset')
                break;
            case 'info':
                onInfoProfect()
                break
            default:
                break;
        }
    }

    const onInfoProfect = (): void => {
        dispatch(getInfoCountAbotProjectsTasksAsync(projectInstance.id))
        setIsModalVisible(true)
    }

    const dumpTasks = (format: string, type: string): void => {
        let allTasks = current[0].tasks;
        allTasks.map((el: any) => dispatch(dumpAllTasksAsync(el.id, format, type)))
    }


    function constructorTotalCounts(data: any) {
        let result = {};

        if (data) {
            const entries = Object.entries(data);

            for (var [key, value] of entries) {
                if (key.includes(TOTAL_COUNTS_OF_PROJECT.KEY)) {
                    let element = key.slice(4, key.length)
                    let ob = { [element]: data[key] }
                    result = { ...result, ...ob }
                } else {
                    let ob = { ['Total Count']: data[key] }
                    result = { ...result, ...ob }
                }
            }

            return Object.entries(result)
                .map(([key, value]) => <p style={{ textTransform: 'capitalize' }}>{key} - {value}</p>)
        } else {
            return <p>An error occured</p>
        }
    }

    const infoModal = (): JSX.Element => {
        return <Modal visible={isModalVisible} onCancel={() => setIsModalVisible(false)} onOk={() => setIsModalVisible(false)} title="Total Info">
            <div>
                {infoFetching ? <Spin /> : constructorTotalCounts(infoCounts)}
            </div>
        </Modal>
    }

    const dumpSubMenu = (): JSX.Element => {
        return <Menu.SubMenu icon={dumpFetching ? <Spin style={{ marginRight: 5 }} /> : null} key={Actions.DUMP_TASK_ANNO} title='Dump annotations'>
            {dumpers
                .sort((a: any, b: any) => a.name.localeCompare(b.name))
                .map(
                    (dumper: any): JSX.Element => {
                        return (
                            <Menu.Item key={dumper.name} className='cvat-menu-dump-submenu-item'>
                                <DownloadOutlined />
                                <Text>{dumper.name}</Text>
                            </Menu.Item>
                        );
                    },
                )}
        </Menu.SubMenu>
    }

    const onEditProject = (): void => setModal('edit')
    const closeModal = (): void => setModal(null)

    const onSubmitForm = (values: any) => {
        let data: any = {};

        if (values.annotators) {
            data.annotators = values.annotators
        }

        projectInstance.annotators = values.annotators ? values.annotators : []

        dispatch(updateProjectAsync(projectInstance))
        closeModal()
    }

    return (
        <>
            {infoModal()}
            <Menu selectable={false} className='cvat-project-actions-menu' onClick={onClickMenuWrapper}>
                <Menu.Item key="delete" onClick={onDeleteProject}>Delete</Menu.Item>
                <Menu.Item key="info">Info</Menu.Item>
                {dumpSubMenu()}
                {ExportSubmenu({
                    exporters: dumpers,
                    exportActivities: null,
                    menuKey: Actions.EXPORT_TASK_DATASET,
                    taskDimension: DimensionType.DIM_2D
                })}
                <EditProjectContent
                    visible={modal === 'edit'}
                    closeModal={closeModal}
                    onSubmitForm={onSubmitForm}
                    projectInstance={projectInstance}
                />
                <Menu.Item onClick={onEditProject}>Edit</Menu.Item>
            </Menu>
        </>
    );
}
