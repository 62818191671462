// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { connect } from 'react-redux';

import JobListComponent from 'components/task-page/job-list';
import { getTasksAsync, updateJobAsync } from 'actions/tasks-actions';
import { Task } from 'reducers/interfaces';

interface OwnProps {
    task: Task;
}

interface DispatchToProps {
    onJobUpdate(jobInstance: any): void;
    getTasks(query: any): void;
}

function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        onJobUpdate: (jobInstance: any): void => dispatch(updateJobAsync(jobInstance)),
        // getTasks(query: TasksQuery): void {
        getTasks(query: any): void {
            dispatch(getTasksAsync(query));
        },
    };
}

function TaskPageContainer(props: DispatchToProps & OwnProps): JSX.Element {
    const { task, onJobUpdate, getTasks } = props;

    return <JobListComponent taskInstance={task.instance} onJobUpdate={onJobUpdate} getTasks={getTasks} />;
}

export default connect(null, mapDispatchToProps)(TaskPageContainer);
