import { Progress, Tooltip } from 'antd'
import React from 'react';

interface IProps {
    total: number;
    completed: number;
    annotators: number;
}

const DashboardProgress = ({ total, completed, annotators }: IProps): JSX.Element => {

    let percent: number = completed * 100 / total;

    return (
        <div className="dashboard-card">
            <h2>Annotators: <b> {annotators}</b></h2>
            <h2>Frames</h2>
            <Tooltip
                title={`${completed} / ${total}`}
            >
                <Progress
                    percent={+percent.toFixed()}
                />
            </Tooltip>
        </div>
    )
}

export default DashboardProgress
