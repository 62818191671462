// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import { Dispatch, ActionCreator } from 'redux';

import { ActionUnion, createAction, ThunkAction } from 'utils/redux';
import { ProjectsQuery, CombinedState } from 'reducers/interfaces';
import { getTasksSuccess, updateTaskSuccess } from 'actions/tasks-actions';
import { getCVATStore } from 'cvat-store';
import getCore from 'cvat-core-wrapper';

const cvat = getCore();

export enum ProjectsActionTypes {
    UPDATE_PROJECTS_GETTING_QUERY = 'UPDATE_PROJECTS_GETTING_QUERY',
    GET_PROJECTS = 'GET_PROJECTS',
    GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS',
    GET_PROJECTS_FAILED = 'GET_PROJECTS_FAILED',
    CREATE_PROJECT = 'CREATE_PROJECT',
    CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS',
    CREATE_PROJECT_FAILED = 'CREATE_PROJECT_FAILED',
    UPDATE_PROJECT = 'UPDATE_PROJECT',
    UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS',
    UPDATE_PROJECT_FAILED = 'UPDATE_PROJECT_FAILED',
    DELETE_PROJECT = 'DELETE_PROJECT',
    DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS',
    DELETE_PROJECT_FAILED = 'DELETE_PROJECT_FAILED',
    INFO_PROJECT = 'INFO_PROJECT',
    INFO_PROJECT_SUCCESS = 'INFO_PROJECT_SUCCESS',
    INFO_PROJECT_ERROR = 'INFO_PROJECT_ERROR',
    INFO_PROJECT_CLEAR = 'INFO_PROJECT_CLEAR',
    DUMP_ALL_TASKS = 'DUMP_ALL_TASKS',
    DUMP_FETCH = 'DUMP_FETCH',
    PROJECTS_FOR_ASK_FOR_NEW_TASK = 'PROJECTS_FOR_ASK_FOR_NEW_TASK',
    PROJECTS_FOR_ASK_FOR_NEW_TASK_SUCCESS = 'PROJECTS_FOR_ASK_FOR_NEW_TASK_SUCCESS',
    PROJECTS_FOR_ASK_FOR_NEW_TASK_FAILED = 'PROJECTS_FOR_ASK_FOR_NEW_TASK_FAILED',
    GET_DASHBOARD = 'GET_DASHBOARD',
    GET_DASHBOARD_SUCCESS = 'GET_DASHBOARD_SUCCESS',
    GET_DASHBOARD_FAILED = 'GET_DASHBOARD_FAILED',
    SET_PROJECT_FILTERS_QUERY = 'SET_PROJECT_FILTERS_QUERY',
    SET_JOB_ASSIGNEES_LIST = 'SET_JOB_ASSIGNEES_LIST'
}

// prettier-ignore
const projectActions = {
    getProjects: () => createAction(ProjectsActionTypes.GET_PROJECTS),
    getProjectsSuccess: (array: any[], count: number) => (
        createAction(ProjectsActionTypes.GET_PROJECTS_SUCCESS, { array, count })
    ),
    getProjectsFailed: (error: any) => createAction(ProjectsActionTypes.GET_PROJECTS_FAILED, { error }),
    updateProjectsGettingQuery: (query: Partial<ProjectsQuery>) => (
        createAction(ProjectsActionTypes.UPDATE_PROJECTS_GETTING_QUERY, { query })
    ),
    createProject: () => createAction(ProjectsActionTypes.CREATE_PROJECT),
    createProjectSuccess: (projectId: number) => (
        createAction(ProjectsActionTypes.CREATE_PROJECT_SUCCESS, { projectId })
    ),
    createProjectFailed: (error: any) => createAction(ProjectsActionTypes.CREATE_PROJECT_FAILED, { error }),
    updateProject: () => createAction(ProjectsActionTypes.UPDATE_PROJECT),
    updateProjectSuccess: (project: any) => createAction(ProjectsActionTypes.UPDATE_PROJECT_SUCCESS, { project }),
    updateProjectFailed: (project: any, error: any) => (
        createAction(ProjectsActionTypes.UPDATE_PROJECT_FAILED, { project, error })
    ),
    deleteProject: (projectId: number) => createAction(ProjectsActionTypes.DELETE_PROJECT, { projectId }),
    deleteProjectSuccess: (projectId: number) => (
        createAction(ProjectsActionTypes.DELETE_PROJECT_SUCCESS, { projectId })
    ),
    deleteProjectFailed: (projectId: number, error: any) => (
        createAction(ProjectsActionTypes.DELETE_PROJECT_FAILED, { projectId, error })
    ),
    getInfoCountForProjectSuccess: (infoCount: number) => createAction(ProjectsActionTypes.INFO_PROJECT_SUCCESS, { infoCount }),
    getInfoCountForProjectError: (error: any) => createAction(ProjectsActionTypes.INFO_PROJECT_ERROR, { error }),
    getInfoCountForProject: () => createAction(ProjectsActionTypes.INFO_PROJECT),
    clearInfoError: () => createAction(ProjectsActionTypes.INFO_PROJECT_CLEAR),
    dumpFetch: () => createAction(ProjectsActionTypes.DUMP_FETCH),
    getProjectsForNewTask: () => createAction(ProjectsActionTypes.PROJECTS_FOR_ASK_FOR_NEW_TASK),
    getProjectsForNewTaskSuccess: (projects: any) => createAction(ProjectsActionTypes.PROJECTS_FOR_ASK_FOR_NEW_TASK_SUCCESS, { projects }),
    getProjectsForNewTaskFailed: (error: any) => createAction(ProjectsActionTypes.PROJECTS_FOR_ASK_FOR_NEW_TASK_FAILED, { error }),
    getDashboard: () => createAction(ProjectsActionTypes.GET_DASHBOARD),
    getDashboardFailed: (error: any) => createAction(ProjectsActionTypes.GET_DASHBOARD_FAILED, { error }),
    getDashboardSuccess: (result: any) => createAction(ProjectsActionTypes.GET_DASHBOARD_SUCCESS, { result }),
    setprojectFiltersQuery: (filters: any) => createAction(ProjectsActionTypes.SET_PROJECT_FILTERS_QUERY, filters),
    setJobAssigneesList: (list: any) => createAction(ProjectsActionTypes.SET_JOB_ASSIGNEES_LIST, list)
};

export type ProjectActions = ActionUnion<typeof projectActions>;

export function getProjectsAsync(query: Partial<ProjectsQuery>): ThunkAction {
    return async (dispatch: ActionCreator<Dispatch>): Promise<void> => {
        dispatch(projectActions.getProjects());
        dispatch(projectActions.updateProjectsGettingQuery(query));

        // Clear query object from null fields
        const filteredQuery: Partial<ProjectsQuery> = {
            page: 1,
            ...query,
        };
        for (const key in filteredQuery) {
            if (filteredQuery[key] === null || typeof filteredQuery[key] === 'undefined') {
                delete filteredQuery[key];
            }
        }

        let result = null;
        try {
            result = await cvat.projects.get(filteredQuery);
        } catch (error) {
            dispatch(projectActions.getProjectsFailed(error));
            return;
        }

        const array = Array.from(result);

        const tasks: any[] = [];
        const taskPreviewPromises: Promise<any>[] = [];

        for (const project of array) {
            taskPreviewPromises.push(
                ...(project as any).tasks.map((task: any, index: number) => {
                    tasks.push(task);
                    if (index === 0) {
                        return (task as any).frames.preview().catch(() => '');
                    }
                }),
            );
        }

        const taskPreviews = await Promise.all(taskPreviewPromises);
        dispatch(projectActions.getProjectsSuccess(array, result.count));

        const store = getCVATStore();
        const state: CombinedState = store.getState();

        if (!state.tasks.fetching) {
            dispatch(
                getTasksSuccess(tasks, taskPreviews, tasks.length, {
                    page: 1,
                    assignee: null,
                    id: null,
                    mode: null,
                    name: null,
                    owner: null,
                    search: null,
                    status: null,
                    sort: null,
                    issues: null,
                    projectId: null,
                    project: null
                }),
            );
        }
    };
}

export function createProjectAsync(data: any): ThunkAction {
    return async (dispatch: ActionCreator<Dispatch>): Promise<void> => {
        const projectInstance = new cvat.classes.Project(data);

        dispatch(projectActions.createProject());
        try {
            const savedProject = await projectInstance.save();
            dispatch(projectActions.createProjectSuccess(savedProject.id));
        } catch (error) {
            dispatch(projectActions.createProjectFailed(error));
        }
    };
}

export function updateProjectAsync(projectInstance: any): ThunkAction {
    return async (dispatch: ActionCreator<Dispatch>): Promise<void> => {
        try {
            dispatch(projectActions.updateProject());
            await projectInstance.save();
            // const [project] = await cvat.projects.get({ id: projectInstance.id });
            // TODO: Check case when a project is not available anymore after update
            // (assignee changes assignee and project is not public)
            // dispatch(projectActions.updateProjectSuccess(project));
            // project.tasks.forEach((task: any) => {
            //     dispatch(updateTaskSuccess(task, task.id));
            // });

        } catch (error) {
            let project = null;
            try {
                // [project] = await cvat.projects.get({ id: projectInstance.id });
            } catch (fetchError) {
                dispatch(projectActions.updateProjectFailed(projectInstance, error));
                return;
            }
            dispatch(projectActions.updateProjectFailed(project, error));
        }
    };
}

export function deleteProjectAsync(projectInstance: any): ThunkAction {
    return async (dispatch: ActionCreator<Dispatch>): Promise<void> => {
        dispatch(projectActions.deleteProject(projectInstance.id));
        try {
            await projectInstance.delete();
            dispatch(projectActions.deleteProjectSuccess(projectInstance.id));
        } catch (error) {
            dispatch(projectActions.deleteProjectFailed(projectInstance.id, error));
        }
    };
}


export const getInfoCountAbotProjectsTasksAsync = (id: number): ThunkAction => async (dispatch: ActionCreator<Dispatch>): Promise<void> => {
    dispatch(projectActions.clearInfoError())
    dispatch(projectActions.getInfoCountForProject())
    try {
        const result = await cvat.projects.getInfoCount(id)
        dispatch(projectActions.getInfoCountForProjectSuccess(result))
    } catch (error) {
        dispatch(projectActions.getInfoCountForProjectError(error))
    }
}

export const clearInfoErrorAsync = (): ThunkAction => async (dispatch: ActionCreator<Dispatch>): Promise<void> => {
    return dispatch(projectActions.clearInfoError())
}

export const dumpAllTasksAsync = (id: number, format: string, type: string): ThunkAction => async (dispatch: ActionCreator<Dispatch>): Promise<void> => {
    dispatch(projectActions.dumpFetch())
    try {
        const result = await cvat.projects.dumpAllTasks(id, format, type)
        const url = await result;
        const downloadAnchor = window.document.getElementById('downloadAnchor') as HTMLAnchorElement;
        downloadAnchor.href = url;
        downloadAnchor.click();
        dispatch(projectActions.dumpFetch())
    } catch (error) {
        dispatch(projectActions.getInfoCountForProjectError(error));
        dispatch(projectActions.dumpFetch())
    }
}

export const getProjectsForAskForNewTask = (annotatorID: number): ThunkAction => {
    return async (dispatch: ActionCreator<Dispatch>): Promise<void> => {
        try {
            dispatch(projectActions.getProjectsForNewTask());
            let response = await cvat.projects.getProjectListFotNewTask(annotatorID);

            if (response.results) {
                dispatch(projectActions.getProjectsForNewTaskSuccess(response.results))
            }
        } catch (error) {
            dispatch(projectActions.getProjectsForNewTaskFailed(error))
        }
    }
}


export const getProjectByIDAsync = (id: number, actualQuery: any): ThunkAction => {
    return async (dispatch: ActionCreator<Dispatch>): Promise<void> => {
        dispatch(projectActions.getProjects());
        let result = null;
        let tasks = [];
        let taskPreviewPromises = [];
        const store = getCVATStore();
        const state: CombinedState = store.getState();

        const filteredQuery = { ...actualQuery };
        for (const key in filteredQuery) {
            if (filteredQuery[key] === null) {
                delete filteredQuery[key];
            }
        }

        try {
            result = await cvat.projects.getProjectsByID(id, filteredQuery);
            tasks = await result.project.tasks;

            dispatch(projectActions.getProjectsSuccess([result.project], 1));
            dispatch(projectActions.setJobAssigneesList(result.job_assignees_list))

            taskPreviewPromises = await result.project.tasks.map((task: any) => task.frames.preview().catch(() => ''));
            const taskPreviews = await Promise.all(taskPreviewPromises);

            dispatch(projectActions.setprojectFiltersQuery(filteredQuery))

            if (!state.tasks.fetching) {
                dispatch(
                    getTasksSuccess(tasks, taskPreviews, result.tasks_count, {
                        page: 1,
                        assignee: null,
                        id: null,
                        mode: null,
                        name: null,
                        owner: null,
                        search: null,
                        status: null,
                    }),
                );
            }
        } catch (error) {
            dispatch(projectActions.getProjectsFailed(error));
            return;
        }
    }
}

export const getDashboardAsync = (projectID?: number | null): ThunkAction => {
    return async (dispatch: ActionCreator<Dispatch>): Promise<void> => {
        dispatch(projectActions.getDashboard());

        try {
            let result = await cvat.projects.dashboard(projectID);
            dispatch(projectActions.getDashboardSuccess(result))
        } catch (error) {
            dispatch(projectActions.getDashboardFailed(error))
        }
    }
}