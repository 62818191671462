import React from 'react'
import { Row, Col, Tabs, Spin } from 'antd';
const { TabPane } = Tabs;
import { IDashboardResult } from 'reducers/interfaces';
import DashboardProgress from './dashboard-progress';
import DashboardPieChart from './dashboard-piechart';
import './styles.scss';

interface IProps {
    data: null | IDashboardResult;
    loading: boolean;
    error: string | null;
    onChangeProject: (id: string) => void
}

const Dashboard = ({ data, loading, error, onChangeProject }: IProps): JSX.Element => {

    return (
        <div className='cvat-dashboar-page'>
            <Row gutter={16} justify='center' align='middle'>
                <Col className='cvat-dashboard-list' md={22} lg={18} xl={16} xxl={14}>
                    <Tabs
                        activeKey={data?.current_project.id.toString()}
                        type="card"
                        onTabClick={onChangeProject}
                    >
                        {
                            loading ? <Spin /> : data?.projects.map(project => {
                                return (
                                    <TabPane
                                        tab={project.name}
                                        key={project.id}
                                        className='cvat-dashboard-list'
                                    >
                                        <DashboardProgress
                                            total={Number(data.additional_info.qty_frames)}
                                            completed={Number(data.additional_info.qty_frames_completed)}
                                            annotators={Number(data.additional_info.qty_annotators)}
                                        />
                                        <DashboardPieChart
                                            data={data.labels_count}
                                        />
                                    </TabPane>
                                )
                            })
                        }
                    </Tabs>
                </Col>
            </Row>

        </div>
    )
}

export default Dashboard
