// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT
//

import './styles.scss';
import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Row, Col, Pagination } from 'antd';
import Spin from 'antd/lib/spin';
import Result from 'antd/lib/result';

import DetailsContainer from 'containers/task-page/details';
import JobListContainer from 'containers/task-page/job-list';
import ModelRunnerModal from 'components/model-runner-modal/model-runner-dialog';
import { Task } from 'reducers/interfaces';
import TopBarComponent from './top-bar';

import { useSelector } from 'react-redux';
import { CombinedState } from 'reducers/interfaces'
import FiltersWrapper from 'components/filters/filters-wrapper';

interface TaskPageComponentProps {
    task: Task | null | undefined;
    fetching: boolean;
    updating: boolean;
    deleteActivity: boolean | null;
    installedGit: boolean;
    isAdmin: boolean;
    getTaskById: (id: number, filters: any) => void;
}

const TaskPageComponent = ({ task, updating, isAdmin, getTaskById, fetching, deleteActivity }: TaskPageComponentProps) => {

    const paramsID = useParams();
    const history = useHistory();
    const location = useLocation();
    const { filtersQuery, job_assignees_list, jobs_count } = useSelector((state: CombinedState) => state.tasks);

    const ID = paramsID.id;

    React.useEffect(() => {
        if (deleteActivity) {
            history.replace('/tasks');
        }
    }, [deleteActivity])


    React.useEffect(() => {
        // if (task === null && !fetching) {
        const filteredQuery: any = { page: filtersQuery.page, page_size: filtersQuery.page_size };
        const params = new URLSearchParams(location.search)

        for (const key in filtersQuery) {
            if (params.get(key)) {
                filteredQuery[key] = params.get(key)
            }
        }
        getTaskById(+ID, filteredQuery)
        // }
    }, [paramsID.id])

    React.useEffect(() => {
        const filteredQuery = { ...filtersQuery };

        for (const key in filteredQuery) {
            if (filteredQuery[key] === null) {
                delete filteredQuery[key];
            }
        }
        const params = new URLSearchParams(filteredQuery);
        history.replace({
            search: params.toString()
        })

    }, [filtersQuery])


    if (task === null) {
        return <Spin size='large' className='cvat-spinner' />;
    }

    if (typeof task === 'undefined') {
        return (
            <Result
                className='cvat-not-found'
                status='404'
                title='Sorry, but this task was not found'
                subTitle='Please, be sure information you tried to get exist and you have access'
            />
        );
    }

    function onReset() {
        getTaskById(+ID, { page: 1, page_size: 10 })
    }

    function changePage(page: number, pageSize: number): void {

        let actualQuery = { ...filtersQuery, page: page, page_size: pageSize };

        getTaskById(+ID, actualQuery)
    }

    function onChangeFilter(value: string, none_value: string, filter_name: string): void {

        let { page, page_size } = filtersQuery;
        // let actualQuery = { ...projectFiltersQuery, [filter_name]: value === none_value ? null : value }; for many filters
        let actualQuery = { page, page_size, [filter_name]: value === none_value ? null : value }; //only for one

        getTaskById(+ID, actualQuery)
    }


    return (
        <>
            <Row
                style={{ display: updating ? 'none' : undefined }}
                justify='center'
                align='top'
                className='cvat-task-details-wrapper'
            >
                <Col md={22} lg={18} xl={16} xxl={14}>
                    <TopBarComponent taskInstance={(task as Task).instance} isAdmin={isAdmin} />
                    <DetailsContainer task={task as Task} />
                    <FiltersWrapper
                        onReset={onReset}
                        onSort={() => { }}
                        onChangeFilter={onChangeFilter}
                        gettingQuery={filtersQuery}
                        job_assignees_list={job_assignees_list || []}
                        hide_sort={true}
                    />
                    <JobListContainer task={task as Task} />
                    <Col style={{ marginTop: 10 }} md={22} lg={22} xl={22} xxl={22}>
                        <Pagination
                            className='cvat-tasks-pagination'
                            onChange={changePage}
                            total={jobs_count}
                            pageSize={+filtersQuery.page_size}
                            current={+filtersQuery.page}
                            pageSizeOptions={[10, 15, 20, 25]}
                            showSizeChanger={true}
                        />
                    </Col>
                </Col>
            </Row>
            <ModelRunnerModal />
            {updating && <Spin size='large' className='cvat-spinner' />}
        </>
    )
}

export default TaskPageComponent;