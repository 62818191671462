// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';

// eslint-disable-next-line import/no-unresolved
import { LydRedLogoIcon } from '@/icons/';

import LoginForm, { LoginData } from './login-form';

interface LoginPageComponentProps {
    fetching: boolean;
    renderResetPassword: boolean;
    onLogin: (username: string, password: string) => void;
}

function LoginPageComponent(props: LoginPageComponentProps & RouteComponentProps): JSX.Element {
    const { fetching, onLogin } = props;

    const columnStyle = {
        display: 'flex',
        flexDirection: 'column',
        flexBasis: '100%',
        flex: 1,
    };

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    width: '100%',
                }}
            >
                <div
                    style={{
                        ...columnStyle,
                        flex: 'none',
                        flexDirection: 'initial',
                        width: '42.29vw',
                    }}
                >
                    <div
                        style={{
                            width: '23.61vw',
                            marginLeft: '9.58vw',
                            marginTop: '4.15vw',
                        }}
                    >
                        <div
                            style={{
                                width: '12.45vw',
                                position: 'relative',
                                left: '-0.25vw',
                                top: '0.055vw',
                            }}
                        >
                            <LydRedLogoIcon />
                        </div>
                        <div
                            style={{
                                marginTop: '7.75vw',
                            }}
                        >
                            <LoginForm
                                fetching={fetching}
                                onSubmit={(loginData: LoginData): void => {
                                    onLogin(loginData.username, loginData.password);
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        ...columnStyle,
                        flexDirection: 'initial',
                        background: 'linear-gradient(135deg, #FFF3A1, #FFC3CF)',
                    }}
                />
            </div>
        </>
    );
}

export default withRouter(LoginPageComponent);
