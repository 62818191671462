import React, { useEffect } from 'react'
import Dashboard from '../../components/dashboard/dashboard';
import { useDispatch, useSelector } from 'react-redux';
import { getDashboardAsync } from 'actions/projects-actions';
import { CombinedState } from 'reducers/interfaces';
import { Empty } from 'antd'

const DashboardContainer: React.FC = (): JSX.Element => {

    const dispatch = useDispatch();
    const { result, error, loading } = useSelector((state: CombinedState) => state.projects.dashboard)

    useEffect(() => {
        dispatch(getDashboardAsync())
    }, [])

    function onChangeProject(id: string): void {
        dispatch(getDashboardAsync(+id))
    }

    if (!result || Object.keys(result).length === 0) {
        return <Empty style={{ height: '100%', marginTop: 50 }} />
    }

    return <Dashboard
        data={result}
        loading={loading}
        error={error}
        onChangeProject={onChangeProject}
    />
}

export default DashboardContainer
