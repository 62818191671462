import React, { useState } from 'react';

import { Form, Col, Button, Modal, Tag, Select } from 'antd';
import {
    SortAscendingOutlined, PlusOutlined, StopOutlined, FilterOutlined, FilterTwoTone
} from '@ant-design/icons';
import FilterIssues from './filter-issues';
import FilterStatus from './filter-status';
import FilterAssignee from './filter-assignee';
import { JobAssignee } from 'reducers/interfaces';

const { Option } = Select;

interface IProps {
    onReset: () => void;
    onSort: (type: string) => void;
    onChangeFilter: (value: string, none_value: string, filter_name: string) => void;
    gettingQuery: {
        [key: string]: string | null
    };
    job_assignees_list: JobAssignee;
    hide_sort?: boolean;
}

const FiltersWrapper = ({ onReset, onSort, onChangeFilter, gettingQuery, job_assignees_list, hide_sort = false }: IProps) => {

    const [visibleModal, setVisibleModal] = useState<boolean>(false);

    return (
        <>
            <span style={{ display: 'flex', flexDirection: 'column', width: '100%', marginLeft: 10, marginTop: 10 }}>
                <Col>
                    {hide_sort === false && <>
                        <Select
                            onChange={onSort}
                            placeholder='Please select sort'
                            value={gettingQuery.sort === null ? 'created_date' : gettingQuery.sort}
                        >
                            <Option value='created_date'>Created date(A - Z)</Option>
                            <Option value='-created_date'>Created date(Z - A)</Option>
                            <Option value='updated_date'>Updated date(A - Z)</Option>
                            <Option value='-updated_date'>Updated date(Z - A)</Option>
                        </Select>
                    </>}

                    <Button
                        onClick={onReset}
                        icon={<StopOutlined />}
                        style={{ margin: '0 5px' }}
                        danger
                    >
                        Reset
                    </Button>
                    <Button onClick={() => setVisibleModal(true)} icon={<FilterTwoTone />} />
                </Col>

                <div style={{ marginBottom: 20, marginTop: 10 }}>
                    {Object.entries(gettingQuery).map(el => Boolean(el[1]) && <Tag key={el[0]}>{`${el[0]} - ${el[1]}`}</Tag>)}
                </div>
            </span>
            <Modal
                title='Advanced filtering'
                closable
                visible={visibleModal}
                onCancel={() => setVisibleModal(false)}
                footer={[
                    <Button key="back" onClick={() => setVisibleModal(false)}>
                        Close
                    </Button>
                ]}
            >
                <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
                    <FilterIssues
                        onChangeFilter={onChangeFilter}
                        value={gettingQuery.issues || 'any'}
                    />
                    <FilterStatus
                        onChangeFilter={onChangeFilter}
                        value={gettingQuery.status || 'any'}
                    />
                    {job_assignees_list.length > 0 && <FilterAssignee
                        onChangeFilter={onChangeFilter}
                        value={gettingQuery.assignee || 'any'}
                        job_assignees_list={job_assignees_list}
                    />}
                </Form>
            </Modal>
        </>
    )
}

export default FiltersWrapper;