import React from 'react';
import { Form, Select } from 'antd';

const { Option } = Select;

interface IProps {
    onChangeFilter: (value: string, none_value: string, filter_name: string) => void
    value: string | null;
}


const FilterStatus = ({ onChangeFilter, value }: IProps) => {
    return (
        <Form.Item label='status:'>
            <Select
                onChange={(value: string) => onChangeFilter(value, 'any', 'status')}
                defaultValue='any'
                placeholder='please select'
                value={value}
            >
                <Option value='any'>- any -</Option>
                <Option value='annotation'>annotation</Option>
                <Option value='completed'>completed</Option>
                <Option value='validation'>validation</Option>
            </Select>
        </Form.Item>
    )
}

export default FilterStatus